module.exports = function fromHex(hex, str) {
  try {
    hex = hex.replace(/[%]/g, "").replace(/[-]/g, "2d")
    str = decodeURIComponent(hex.replace(/(..)/g, "%$1"))
  } catch (e) {
    str = hex
    console.log("invalid hex input: " + hex)
  }
  return str
}
