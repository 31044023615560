import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import { graphql, Link } from "gatsby"
import { Product } from "../components/common/Products/Product"
import { makeStyles } from "@material-ui/core/styles"

import { useSelector } from "react-redux"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    margin: "0px auto",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
    maxWidth: "1200px",
  },
  productsWrapper: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
}))

const renderProducts = data => {
  if (!data.subpages) {
    if (!data.images) {
      return null
    }
    return data.images.map(image => {
      return (
        <Product
          key={`Product-${image.alt}`}
          image={image}
          width="350px"
          height="350px"
          // detail_en={data.title_en}
          // detail_fa={data.title_fa}
        ></Product>
      )
    })
  }
  const products = data.subpages.map(subpage => {
    if (!subpage.images) {
      return null
    }
    return (
      <Product
        key={`Product-${subpage.images[0].alt}`}
        image={subpage.images[0]}
        slug={subpage.slug}
        width="350px"
        height="350px"
        detail_en={subpage.title_en}
        detail_fa={subpage.title_fa}
      ></Product>
    )
  })
  return products
}
export default ({ pathContext }) => {
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)

  const data = pathContext.data
  const [language, setLanguage] = React.useState(lang)
  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  let textAlign = "left"
  let textAlignLast = "left"
  if (dir === "rtl") {
    textAlign = "right"
    textAlignLast = "right"
  }

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Layout>
      <SEO title="Product category" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2vh",
        }}
      >
        <div className={classes.contentWrapper}>
          <div
            className={classes.detailWrapper}
            style={{
              textAlign: `${textAlign}`,
              direction: dir,
              textAlignLast: textAlignLast,
            }}
            dangerouslySetInnerHTML={{
              __html: lang === "en" ? data.content_en : data.content_fa,
            }}
          ></div>
        </div>
        <div className={classes.productsWrapper}>{renderProducts(data)}</div>
      </div>
    </Layout>
  )
}
