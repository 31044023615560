import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Img from "gatsby-image"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    marginBottom: "20px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

export default function ProductCard({
  products,
  title,
  subtitle,
  benefits,
  productLink,
  image,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  function handleExpandClick() {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.card}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: "1" }}>
          <Link to={`/${productLink}`}>
            <div style={{ flex: "1", justifyContent: "center" }}>
              <Img fluid={image} />
            </div>
          </Link>
        </div>
        <CardContent style={{ flex: "1" }}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontSize: "20pt" }}
            component="h1"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <br></br>
            {benefits}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            {subtitle}
          </Typography>
        </CardContent>
      </div>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {products}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}
