import React from "react"
import { Link } from "gatsby"
import { Button } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { makeStyles } from "@material-ui/core/styles"
import ProductFlippable from "../components/common/productFlip"
import { connect } from "react-redux"
import dictionary from "../languages"
import Img from "gatsby-image"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { ArrowForward, ArrowBack } from "@material-ui/icons"
import data from "../data.json"

const useStyles = makeStyles(theme => ({
  carousleArrow: {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
    color: "gray",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    margin: "0px auto",
    minHeight: "70vh",
    minWidth: "50vw",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
}))

const CarouselSlide = ({ image, title, detail, link, dir }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const classes = useStyles()
  let slug = ""

  if (link[link.length - 1] === "about-us") {
    slug = "aboutus"
  } else if (
    link[link.length - 1] ===
      "%D8%AE%D9%88%D8%A7%D8%B5-%D9%88-%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%AF-%D9%BE%D8%B3%D8%AA%D9%87" ||
    link[link.length - 1] === "pistachio"
  ) {
    slug = "pistachio"
  } else if (
    link[link.length - 1] ===
      "%D8%A7%D8%B2%D9%85%D8%A7%DB%8C%D8%B4%DA%AF%D8%A7%D9%87-%D8%B5%D8%AF%D9%81-%D8%B3%D8%A8%D8%B2" ||
    link[link.length - 1] === "laboratory"
  ) {
    slug = "laboratory"
  }

  let textAlign = "left"
  if (dir === "rtl") {
    textAlign = "right"
  }

  let selfAlign = "flex-start"
  if (dir === "rtl") {
    selfAlign = "flex-end"
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isDesktop ? "row" : "column",
        justifyContent: "center",
        height: "100%",
        Width: "100%",
        maxWidth: "100vw",
        maxHeight: "100vh",
        backgroundColor: "white",
        flexWrap: "wrap",
        padding: "0.5rem",
      }}
    >
      <div
        style={{
          display: isDesktop ? "none" : "flex",
          paddingTop: "20vw",
        }}
      >
        <Link to={`/${slug}`} style={{ textDecoration: "none", flex: "1" }}>
          <Button
            style={{ fontWeight: "bold", fontSize: "14pt", color: "#51671b" }}
          >
            {title}
          </Button>
        </Link>
      </div>
      <div
        className={classes.imageWrapper}
        style={{ flex: "1", display: "flex" }}
      >
        <Link
          to={`/${slug}`}
          style={{ textDecoration: "none", flex: "1", margin: "auto" }}
        >
          <Img
            alt={image.alt}
            title={image.alt}
            fluid={image.localFile.childImageSharp.fluid}
          />
        </Link>
      </div>
      <div
        style={{
          flex: "1.5",
          display: isDesktop ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", alignSelf: `${selfAlign}` }}>
          <Link to={`/${slug}`} style={{ textDecoration: "none", flex: "1" }}>
            <Button
              style={{ fontWeight: "bold", fontSize: "14pt", color: "#51671b" }}
            >
              {title}
            </Button>
          </Link>
        </div>
        <p style={{ textAlign: `${textAlign}`, color: "#51671b" }}>{detail}</p>
      </div>
    </div>
  )
}

const renderCarousel = (images, classes, isDesktop) => {
  return images.map(image => {
    return (
      <div
        key={`carousle-image-key${image.alt}`}
        className={classes.imageWrapper}
      >
        {/* <SliderImage fileName="Sadaf-enhanced.jpg" alt="logo" /> */}
        <div
          className={classes.imgWrapper}
          style={{ width: `${isDesktop ? "400px" : "300px"}` }}
        >
          <img
            src={require(`../${image.url}`)}
            alt={image.alt}
            className={classes.imgStyle}
          ></img>
        </div>
      </div>
    )
  })
}

const filterLang = (data, lang) => {
  const a = data.allPrismicCarrousel.nodes.filter(node => {
    if (lang === "fa") {
      return node.lang === "fa-ir"
    } else if (lang === "en") {
      return node.lang === "en-us"
    }
    return false
  })
  return a
}

const IndexPage = ({ lang, dir }) => {
  const classes = useStyles()
  const [dict, setDict] = React.useState(dictionary(lang))
  const [language, setLanguage] = React.useState(lang)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const content = data.pages.find(page => page.type === "home").images
  React.useEffect(() => {
    setDict(dictionary(language))
  }, [language])

  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  return (
    <Layout>
      <SEO title="SadafSabz | Pistachio supplier" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "1",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <Button
                  onClick={onClickHandler}
                  title={label}
                  style={{ left: 15 }}
                  className={classes.carousleArrow}
                >
                  <ArrowBack></ArrowBack>
                </Button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <Button
                  onClick={onClickHandler}
                  title={label}
                  className={classes.carousleArrow}
                  style={{ right: 15 }}
                >
                  <ArrowForward></ArrowForward>
                </Button>
              )
            }
            autoPlay
          >
            <div className={classes.imageWrapper}>
              {/* <SliderImage fileName="Sadaf-enhanced.jpg" alt="logo" /> */}
              <div
                className={classes.imgWrapper}
                style={{ width: `${isDesktop ? "400px" : "300px"}` }}
              >
                <img
                  src={require(`../images/Sadaf-enhanced.jpg`)}
                  alt={"SadafSabz"}
                  className={classes.imgStyle}
                ></img>
              </div>
            </div>
            {renderCarousel(content, classes, isDesktop)}
          </Carousel>
        </div>
        <div
          style={{
            flex: "1",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            backgroundColor: "#8eb44387",
            borderRadius: "2%",
          }}
        >
          <h1
            style={{
              flex: "1",
              padding: "2vh 4vw 0px 4vw ",
              direction: `${dir}`,
              fontSize: isDesktop ? "24pt" : "6vw",
              color: "#51671b",
            }}
          >
            {dict.someOfOurProducts}{" "}
          </h1>
          <h2
            style={{
              flex: "1",
              padding: "0px 4vw 1vh 4vw",
              direction: `${dir}`,
              fontSize: isDesktop ? "12pt" : "4vw",
              color: "#51671b",
            }}
          >
            {dict.someOfOurProductsSub}{" "}
          </h2>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ProductFlippable
              style={{ flex: "1", justifyContent: "center" }}
            ></ProductFlippable>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(IndexPage)
