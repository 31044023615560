import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import hexHandler from "../../../utils/hexHandler"
import FlipablbleCard from "./ProductFlippable"
import data from "../../../data.json"

const filterData = (data, lang) => {
  return data.products.nodes.filter(node => {
    if (lang === "fa") {
      return node.lang === "fa-ir"
    } else if (lang === "en") {
      return node.lang === "en-us"
    }
    return false
  })
}

const renderCards = products => {
  return products.pages.map((prod, index) => {
    const link = prod.slug

    return (
      <FlipablbleCard
        key={`${link}${index}`}
        product={prod}
        productLink={link}
        index={index}
      ></FlipablbleCard>
    )
  })
}

const ProductFlipabbleCard = ({ lang }) => {
  const content = data.pages.find(page => page.type === "all-products")

  return renderCards(content)
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}

export default connect(mapStateToProps)(ProductFlipabbleCard)
