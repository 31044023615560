import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const SliderImage = ({ fileName, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(fileName)
  })
  if (!image) {
    return null
  }

  const imageSizes = image.node.childImageSharp.fixed
  return <Img alt={alt} title={alt} fixed={imageSizes} />
}

export default SliderImage
