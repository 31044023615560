import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import ProductPanel from "./ProductPanel"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { TabScrollButton } from "@material-ui/core"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "90%",
    width: "100%",
  },
  indicator: {
    backgroundColor: "rgba(109, 128, 64, 0.5)",
    opacity: "50%",
    height: "100%",
  },
  base: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}))

function ProductTabs({ products, selectedProduct, changeProduct, lang, dir }) {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const [value, setValue] = React.useState(selectedProduct.index)

  const handleChange = (event, newValue) => {
    changeProduct({ index: newValue, productName: event.target.textContent })
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const renderTabs = products => {
    return products.map((node, index) => {
      return (
        <Tab
          key={`${node.data.title.text}${index}`}
          label={`${node.data.title.text}`}
          {...a11yProps(index)}
          style={{ margin: " 0px 1vw" }}
        ></Tab>
      )
    })
  }
  const renderPanel = (products, value, dir) => {
    return products.map((node, index) => {
      return (
        <TabPanel
          key={`${node.data.title.text}${index}`}
          value={value}
          index={index}
          dir={theme.direction}
        >
          <ProductPanel data={node} lang={lang} dir={dir}></ProductPanel>
        </TabPanel>
      )
    })
  }
  return (
    <div className={classes.root} style={{ justifyContent: "center" }}>
      <AppBar
        position="static"
        color="default"
        style={{ marginBottom: "30px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          ScrollButtonComponent={
            isDesktop
              ? TabScrollButton
              : e => {
                if (e.direction === "right") {
                  return (
                    <Button
                      onClick={() => {
                        if (value !== products.length - 1) {
                          setValue(value + 1)
                        }
                      }}
                    >
                      >
                    </Button>
                  )
                } else {
                  return (
                    <Button
                      onClick={() => {
                        if (value !== 0) {
                          setValue(value - 1)
                        }
                      }}
                    >
                      {`<`}
                    </Button>
                  )
                }
              }
          }
          classes={{
            indicator: classes.indicator,
            root: classes.base,
            scroller: classes.scroller,
          }}
        >
          {renderTabs(products)}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderPanel(products, value, dir)}
      </SwipeableViews>
    </div>
  )
}

export default ProductTabs
