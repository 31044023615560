import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import { ProductCardView as Product } from "../Products/Product"
import { Link } from "gatsby"
import { connect } from "react-redux"
import dictionary from "../../../languages"
import { changeProduct } from "../../../redux/actions/currentSelectedProduct"
import { changeRoute } from "../../../redux/actions/currentPageAction"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles({
  card: {
    minHeight: "350px",
    minWidth: "350px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  cardMobile: {
    minHeight: "80vw",
    minWidth: "80vw",
    maxWidth: "80vw",
    maxHeight: "80vw",
  },
})

export const FrontCard = ({ handleFlip, productImage, productName }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <Card
      onMouseEnter={handleFlip}
      onMouseLeave={handleFlip}
      onClick={handleFlip}
      className={isDesktop ? classes.card : classes.cardMobile}
    >
      <CardContent style={{ display: "flex", justifyContent: "center" }}>
        <Product
          key={productName}
          image={productImage}
          detail={productName}
          width={isDesktop ? "300px" : "65vw"}
          height={isDesktop ? "300px" : "65vw"}
        ></Product>
      </CardContent>
    </Card>
  )
}

const BackCard = ({
  handleFlip,
  productLink,
  productImage,
  productName,
  lang,
  changeProduct,
  changeRoute,
  index,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const [dict, setDict] = React.useState(dictionary(lang))
  React.useEffect(() => {
    setDict(dictionary(lang))
  }, [lang])
  const image = require(`../../../${productImage.url}`)
  return (
    <Card
      onMouseEnter={handleFlip}
      onMouseLeave={handleFlip}
      onClick={handleFlip}
      className={isDesktop ? classes.card : classes.cardMobile}
      style={{
        display: "flex",
        backgroundColor: "white",
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          marginBottom: "20px",
          alignItems: "flex-end",
        }}
      >
        <Link to={`/${productLink}`} style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#c6b657",
              minHeight: "60px",
              color: "white",
            }}
          >
            {dict.aboutProduct}
          </Button>
        </Link>
      </div>
    </Card>
  )
}
const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(
  mapStateToProps,
  { changeProduct, changeRoute }
)(BackCard)
