import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { makeStyles } from "@material-ui/core/styles"

import { connect } from "react-redux"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import data from "../data.json"
import { ArrowForward, ArrowBack } from "@material-ui/icons"
import { Button } from "@material-ui/core"
const useStyles = makeStyles(theme => ({
  carousleArrow: {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
    color: "black",
  },
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "table-cell",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
  },
}))

const CarouselSlide = image => {
  const classes = useStyles()

  return (
    <div className={classes.imageWrapper}>
      <div className={classes.imgWrapper}>
        <img
          src={require(`../${image.image.url}`)}
          alt={image.image.alt}
          className={classes.imgStyle}
        ></img>
      </div>
    </div>
  )
}

const renderCarousel = images => {
  return images.map(image => {
    return (
      <CarouselSlide
        key={`CarouselKEY_${image.alt}`}
        image={image}
      ></CarouselSlide>
    )
  })
}

const AboutUsPage = ({ lang, dir }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  const historyPages = data.pages.find(page => page.type === "history")
  const aboutusData = data.pages.find(page => page.type === "aboutus")
  const [language, setLanguage] = React.useState(lang)

  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  let textAlign = "left"
  if (dir === "rtl") {
    textAlign = "right"
  }

  let selfAlign = "flex-start"
  if (dir === "rtl") {
    selfAlign = "flex-end"
  }

  return (
    <Layout>
      <SEO title="About us" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2vh",
        }}
      >
        <div className={classes.contentWrapper}>
          <div
            className={classes.imageWrapper}
            style={{ flex: "1", display: "flex", marginTop: "20px" }}
          >
            <div
              className={classes.imgWrapper}
              style={{ width: `${isDesktop ? "400px" : "300px"}` }}
            >
              <img
                src={require(`../${aboutusData.images[0].url}`)}
                alt={`${aboutusData.images[0].alt}`}
                className={classes.imgStyle}
              ></img>
            </div>
          </div>
          <div
            className={classes.detailWrapper}
            style={{ textAlign: `${textAlign}`, direction: dir }}
            dangerouslySetInnerHTML={{
              __html:
                lang === "en" ? aboutusData.content_en : aboutusData.content_fa,
            }}
          ></div>
        </div>
        <div className={classes.carouselWrapper}>
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <Button
                  onClick={onClickHandler}
                  title={label}
                  style={{ left: 15, background: isDesktop && "#a9a9a99e" }}
                  className={classes.carousleArrow}
                >
                  <ArrowBack></ArrowBack>
                </Button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <Button
                  onClick={onClickHandler}
                  title={label}
                  className={classes.carousleArrow}
                  style={{ right: 15, background: isDesktop && "#a9a9a99e" }}
                >
                  <ArrowForward></ArrowForward>
                </Button>
              )
            }
            autoPlay
            centerSlidePercentage={isDesktop ? 40 : 100}
            centerMode={true}
          >
            {renderCarousel(aboutusData.images)}
          </Carousel>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(AboutUsPage)
