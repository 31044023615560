import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import { useSelector } from "react-redux"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    margin: "0px auto",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
  },
}))

const renderPdfs = pdfs => {
  return (
    <ul>
      {pdfs.map(pdf => {
        return (
          <li key={pdf.title_en}>
            <Button
              style={{ textAlign: "left" }}
              // onClick={() => {
              //   const uri = require(`../${pdf.url}`)
              //   window.open(uri)
              // }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={require(`../${pdf.url}`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pdf.title_en}
              </a>
            </Button>
          </li>
        )
      })}
    </ul>
  )
}
export default ({ pathContext }) => {
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const [language, setLanguage] = React.useState(lang)
  const data = pathContext.data
  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  let textAlign = "left"
  if (dir === "rtl") {
    textAlign = "right"
  }

  let selfAlign = "flex-start"
  if (dir === "rtl") {
    selfAlign = "flex-end"
  }

  return (
    <Layout>
      <SEO title="History" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2vh",
        }}
      >
        <div className={classes.contentWrapper}>
          {data.pdfs && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className={classes.detailWrapper}
                style={{ textAlign: `${textAlign}`, direction: dir }}
                dangerouslySetInnerHTML={{
                  __html: lang === "en" ? data.content_en : data.content_fa,
                }}
              ></div>
              <div>{renderPdfs(data.pdfs)}</div>
            </div>
          )}
          {data.images && (
            <>
              <div
                className={classes.imageWrapper}
                style={{ flex: "1", display: "flex", marginTop: "20px" }}
              >
                <div
                  className={classes.imgWrapper}
                  style={{ width: `${isDesktop ? "400px" : "300px"}` }}
                >
                  <img
                    src={require(`../${data.images[0].url}`)}
                    alt={`${data.images[0].alt}`}
                    className={classes.imgStyle}
                  ></img>
                </div>
              </div>
              <div
                className={classes.detailWrapper}
                style={{ textAlign: `${textAlign}`, direction: dir }}
                dangerouslySetInnerHTML={{
                  __html: lang === "en" ? data.content_en : data.content_fa,
                }}
              ></div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}
