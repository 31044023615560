import React, { useState } from "react"
import { Product } from "./Product"
import { Divider } from "@material-ui/core"
import dictionary from "../../../languages"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const renderProducts = data => {
  const products = data.data.images.map((image, index) => {
    if (
      image.product_image &&
      image.product_image.alt &&
      image.product_image.localFile
    ) {
      return (
        <Product
          key={`${image.product_image.alt} ${index}`}
          image={image.product_image.localFile.childImageSharp.fixed}
          detail={image.product_image.alt}
          width="350px"
          height="400px"
        ></Product>
      )
    }
  })
  return products
}
const renderProductSheet = (data, lang) => {
  if (data.data.product_sheet && data.data.product_sheet.url) {
    return (
      <a
        style={{ textAlign: "center", color: "#51671b", marginBottom: "20px" }}
        href={data.data.product_sheet.url}
      >
        {dictionary(lang).checkoutProductSheet}
      </a>
    )
  }
}
export default ({ data, lang, dir }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          marginBottom: "20px",
        }}
      >
        <Divider
          variant="middle"
          style={{
            flex: "1",
            marginTop: "20px",
          }}
        />
        <h1
          style={{
            textAlign: "center",
            color: "#51671b",
            fontSize: isDesktop || "7vw",
          }}
        >
          {" "}
          {data.data.title.text}
        </h1>

        <Divider
          variant="middle"
          style={{
            flex: "1",
            marginTop: "20px",
          }}
        />
      </div>
      <div
        style={{
          flex: "1",
          maxWidth: "70%",
          alignSelf: "center",
          marginBottom: "1vw",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            lineHeight: "1.5rem",
            color: "#51671b",
            fontSize: isDesktop || "3vw",
            maxWidth: "90%",
            direction: `${dir}`,
          }}
        >
          {" "}
          {data.data.benefits.text}
        </p>
      </div>
      {renderProductSheet(data, lang)}
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {renderProducts(data)}
      </div>
    </div>
  )
}
