import React, { useState, useEffect } from "react"
import ReactCardFlip from "react-card-flip"
import BackCard, { FrontCard } from "./Card"
import { makeStyles } from "@material-ui/core/styles"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  card: {
    // paddingRight: "20px",
    // paddingLeft: "20px",
    // paddingBottom: "20px",
    padding: "20px",
  },
  cardMobile: {
    padding: "20px 0px",
  },
}))

const ProductFlippable = ({ product, productLink, index }) => {
  const classes = useStyles()
  const [isFlipped, toggleFlip] = useState(false)
  const lang = useSelector(state => state.language.lang)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const handleFlip = e => {
    e.preventDefault()
    let delay = 500
    if (!toggleFlip) {
      delay = 10
    }
    setTimeout(() => {
      toggleFlip(prevState => !prevState)
    }, delay)
  }

  return (
    <div className={isDesktop ? classes.card : classes.cardMobile}>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <FrontCard
          key="front"
          productImage={
            product.images ? product.images[0] : product.subpages[0].images[0]
          }
          productName={lang === "en" ? product.title_en : product.title_fa}
          handleFlip={handleFlip}
        ></FrontCard>

        <BackCard
          key="back"
          handleFlip={handleFlip}
          productLink={productLink}
          productImage={
            product.images ? product.images[0] : product.subpages[0].images[0]
          }
          productName={lang === "en" ? product.title_en : product.title_fa}
          index={index}
        ></BackCard>
      </ReactCardFlip>
    </div>
  )
}

export default ProductFlippable
