import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
const useStyles = makeStyles(theme => ({
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    margin: "0px auto",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
  },
}))

export const Product = ({
  image,
  slug = null,
  width = "200px",
  height = "200px",
  detail_en = null,
  detail_fa = null,
}) => {
  const lang = useSelector(state => state.language.lang)
  const dir = useSelector(state => state.language.dir)
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const [language, setLanguage] = React.useState(lang)

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: `${height}`,
        width: `${width}`,
        maxWidth: "100%",
        maxHeight: "100%",
        padding: "10px",
        backgroundColor: "rgb(156, 173, 116)",
        borderRadius: "2%",
        margin: "10px",
      }}
    >
      <div
        className={classes.imageWrapper}
        style={{ flex: "1", display: "flex", marginTop: "20px" }}
      >
        <div
          className={classes.imgWrapper}
          style={{ width: `${isDesktop ? "400px" : "300px"}` }}
        >
          {slug && (
            <Link to={`/${slug}`}>
              <img
                src={require(`../../../${image.url}`)}
                alt={`${image.alt}`}
                className={classes.imgStyle}
              ></img>
            </Link>
          )}
          {!slug && (
            <img
              src={require(`../../../${image.url}`)}
              alt={`${image.alt}`}
              className={classes.imgStyle}
            ></img>
          )}
        </div>
      </div>
      <p
        style={{
          textAlign: "center",
          fontSize: "14pt",
          fontWeight: "500",
          color: "white",
          marginTop: "0.5rem",
        }}
      >
        {!detail_en
          ? lang === "en"
            ? image.title_en
            : image.title_fa
          : lang === "en"
          ? detail_en
          : detail_fa}
      </p>
    </div>
  )
}
export const ProductCardView = ({
  image,
  detail,
  width = "200px",
  height = "200px",
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: `${height}`,
        width: `${width}`,
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      <div
        style={{
          flex: "10",
          justifyContent: "center",
          height: `${height}`,
          width: `${width}`,
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      >
        <div
          className={classes.imageWrapper}
          style={{ flex: "1", display: "flex", marginTop: "20px" }}
        >
          <div
            className={classes.imgWrapper}
            style={{ width: `${isDesktop ? "400px" : "300px"}` }}
          >
            <img
              src={require(`../../../${image.url}`)}
              alt={`${image.alt}`}
              className={classes.imgStyle}
            ></img>
          </div>
        </div>
      </div>
      <p
        style={{
          flex: "1",
          fontSize: "11pt",
          fontWeight: "500",
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          justifyContent: "center",
        }}
      >
        {detail}
      </p>
    </div>
  )
}
