import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { ArrowForward, ArrowBack } from "@material-ui/icons"
import { Button } from "@material-ui/core"

import data from "../data.json"

const useStyles = makeStyles(theme => ({
  carousleArrow: {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
    color: "black",
  },
  contentWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
    marginBottom: "3rem",
    flexWrap: "wrap",
  },
  imageWrapper: {
    height: "100%",
    Width: "100%",
    backgroundColor: "white",
  },
  imgWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "400px",
    height: "266px",
  },
  imgStyle: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
    transitionDelay: "500ms",
  },
  carouselWrapper: {
    width: "100%",
    border: "#6d8041",
    borderStyle: "inset",
    borderRadius: "1%",
    borderWidth: "thin",
    padding: "1rem",
  },
  detailWrapper: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem",
    color: "#51671b",
  },
}))

const renderPdfs = pdfs => {
  return (
    <ul>
      {pdfs.map(pdf => {
        return (
          <li key={pdf.title_en}>
            <Button
              onClick={() => {
                window.open(require(`../${pdf.url}`))
              }}
              //   href={require(`../${pdf.url}`)}
            >
              {pdf.title_en}
            </Button>
          </li>
        )
      })}
    </ul>
  )
}
const LaboratoryPage = ({ lang, dir }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  const [language, setLanguage] = React.useState(lang)

  const certificateContent = data.pages.find(
    page => page.type === "certificates"
  )
  console.log(certificateContent)
  React.useEffect(() => {
    setLanguage(lang)
  }, [lang])

  let textAlign = "left"
  if (dir === "rtl") {
    textAlign = "right"
  }

  let selfAlign = "flex-start"
  if (dir === "rtl") {
    selfAlign = "flex-end"
  }

  return (
    <Layout>
      <SEO title="Laboratory" />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          width: "80vw",
          alignItems: "self-start",
        }}
      >
        <div className={classes.contentWrapper}>
          {certificateContent && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                  className={classes.detailWrapper}
                  style={{ textAlign: `${textAlign}`, direction: dir }}
                  dangerouslySetInnerHTML={{
                    __html: lang === "en" ? certificateContent.content_en : certificateContent.content_fa,
                  }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.language.lang,
    dir: state.language.dir,
  }
}
export default connect(mapStateToProps)(LaboratoryPage)
